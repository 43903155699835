import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import Contato from '../components/contato1/contato1'
import LandingpageVagas from "../components/landingpagevagas/landingpagevagas";
import '../styles/vagas.scss'
import Header from '../components/header/header'

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { allMarkdownRemark, markdownRemark } = data // data.markdownRemark holds your post data
    const dados = markdownRemark.frontmatter
    return (
        <div>
            <Header headerData={dados.header[0]}></Header>
            <Layout>
                <LandingpageVagas texto={"VAGAS"}></LandingpageVagas>
                <div className="vagas-container">
                    <div className="vagas-wrapper">
                        <h1>Processos seletivos</h1>
                        <div className="vagas-cards">
                            {generateAllLinks(allMarkdownRemark.edges)}
                        </div>
                    </div>
                </div>
            </Layout >
        </div>
    )
}

const generateAllLinks = vagas => vagas
    .map(markdown => {
        const { frontmatter: { path, title, date, desc } } = markdown.node
        if (path != '/')
            return (
                <a href={path} className="card-vaga" key={path}>
                    <div className="vaga-header">
                        <h1 className="vaga-title">{title}</h1>
                        <p className="vaga-date">Aplicar até: {date}</p>
                    </div>
                    <div className="vaga-description">
                        <p>{desc}</p>
                        <a href={path}>Saiba mais</a>
                    </div>

                </a>
            )
    })


export const pageQuery = graphql`
{
    allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___date] }
        limit: 1000
    ) {
        edges {
        node {
            frontmatter {
            path
            title
            desc
            date(formatString: "DD MMMM, YYYY", locale: "pt-br")
            }
        }
    }
    }
    markdownRemark(frontmatter: {template: {eq: "default"}}) {
        frontmatter {
          title
          path
          header {
            pageTitle
            keyWords
            descricao
            gtmID
            gaID
          } 
        }
      }
}
`
